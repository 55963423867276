<template>
  <div class="rightContent">
    <!-- <div class="userAuths">
      <div class="auth" v-for="(p, key) in permissionConfig" :key="key">
        {{ p.text }}：{{ p.value }}
      </div>
    </div> -->
    <div class="service">
      <div class="title">运营客服</div>
      <el-carousel arrow="always" indicator-position="none" :autoplay="false">
        <el-carousel-item v-for="(item, index) in serviceUsers" :key="index">
          <img class="image" :src="item.src" />
          <!-- <div class="line">联 系 人：{{ item.userName }}</div>
          <div class="line">联系电话：{{ item.phone }}</div> -->
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      permissionConfig: {
        storage: {
          text: "存储空间",
          value: "1036/50M",
        },
        projectNum: {
          text: "工程数量",
          value: "0/5个",
        },
        materialContent: {
          text: "素材内容",
          value: "0/50个",
        },
      },
      serviceUsers: [
        {
          src: require("../../../assets/codePic.png"),
          userName: "颜立波",
          phone: 13366850362,
        },
      ],
    };
  },
};
</script>

<style  lang='scss'>
.rightContent {
  width: 300px;
  background-color: #e2e3e5;
  padding: 16px;
  border-radius: 12px;
  .userAuths {
    background-color: #fff;
    padding: 8px;
    border-radius: 6px;
    color: #333;
    line-height: 26px;
  }
  .service {
    .title {
      font-size: 22px;
      margin: 22px 0;
    }
    .line {
      margin: 10px;
    }
    .image {
      height: 338px;
      border-radius: 12px;
    }
    .el-carousel__item {
      text-align: center;
    }
    .el-carousel__container {
      height: 387px !important;
    }
  }
}
</style>